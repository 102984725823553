body {
    background-color: black;
    color: white;
}

.title-center {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 72pt;
    margin-top: 40vh;
}
.text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    font-size: 12pt;
}
